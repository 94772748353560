import * as Validators from './Validators.js'

export const settingsObj = {
    name: '',

    target_ph: '',
    target_ph_range0: '',
    target_ph_range1: '',
    target_ph_range2: '',
    offset_ph: '',
    pumpTime_ph_range0: '',
    pumpTime_ph_range1: '',
    pumpTime_ph_range2: '',
    cooldown_ph_range0: '',
    cooldown_ph_range1: '',
    cooldown_ph_range2: '',

    target_orp: '',
    target_orp_range0: '',
    target_orp_range1: '',
    target_orp_range2: '',
    offset_orp: '',
    pumpTime_orp_range0: '',
    pumpTime_orp_range1: '',
    pumpTime_orp_range2: '',
    cooldown_orp_range0: '',
    cooldown_orp_range1: '',
    cooldown_orp_range2: '',

    frequency_clsb: '',
    flash_frequency_clsb: '',

    pumpTime_cl: '',
    cooldown_cl: '',
    pumpTime_ms: '',
    cooldown_ms: '',
    pumpTime_sb: '',
    cooldown_sb: '',

    target_temp: '',
};

export const settingsValidators = {
    name: [],

    target_ph: [new Validators.BoundsI(7.2, 7.8)],
    target_ph_range0: [new Validators.IsNumber()],
    target_ph_range1: [new Validators.IsNumber()],
    target_ph_range2: [new Validators.IsNumber()],
    offset_ph: [new Validators.IsNumber()],
    pumpTime_ph_range0: [new Validators.IsInt(), new Validators.NonNeg()],
    pumpTime_ph_range1: [new Validators.IsInt(), new Validators.NonNeg()],
    pumpTime_ph_range2: [new Validators.IsInt(), new Validators.NonNeg()],
    cooldown_ph_range0: [new Validators.IsInt(), new Validators.NonNeg()],
    cooldown_ph_range1: [new Validators.IsInt(), new Validators.NonNeg()],
    cooldown_ph_range2: [new Validators.IsInt(), new Validators.NonNeg()],

    target_orp: [new Validators.BoundsI(450, 1000)],
    target_orp_range0: [new Validators.IsNumber()],
    target_orp_range1: [new Validators.IsNumber()],
    target_orp_range2: [new Validators.IsNumber()],
    offset_orp: [new Validators.IsNumber()],
    pumpTime_orp_range0: [new Validators.NonNeg()],
    pumpTime_orp_range1: [new Validators.NonNeg()],
    pumpTime_orp_range2: [new Validators.NonNeg()],
    cooldown_orp_range0: [new Validators.NonNeg()],
    cooldown_orp_range1: [new Validators.NonNeg()],
    cooldown_orp_range2: [new Validators.NonNeg()],

    frequency_clsb: [new Validators.IsInt(), new Validators.NonNeg()],
    flash_frequency_clsb: [new Validators.IsInt(), new Validators.NonNeg()],

    pumpTime_cl: [new Validators.IsInt(), new Validators.NonNeg()],
    cooldown_cl: [new Validators.IsInt(), new Validators.NonNeg()],
    pumpTime_ms: [new Validators.IsInt(), new Validators.NonNeg()],
    cooldown_ms: [new Validators.IsInt(), new Validators.NonNeg()],
    pumpTime_sb: [new Validators.IsInt(), new Validators.NonNeg()],
    cooldown_sb: [new Validators.IsInt(), new Validators.NonNeg()],

    target_temp: [new Validators.IsInt(), new Validators.NonNeg()],
};
